import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, TextField, Button, MenuItem } from '@mui/material';
// import axios from 'axios';

const designations = [
  { value: 'Engineer', label: 'Engineer' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Technician', label: 'Technician' },
];

function ResumeForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    designation: '',
  });
  const [status, setStatus] = useState('');
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    console.log("name, value, files===>", name, value, files)
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log("formData onChange==>", formData)
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');

    // const formDataToSend = new FormData();
    // formDataToSend.append('name', formData.name);
    // formDataToSend.append('email', formData.email);
    // formDataToSend.append('phone', formData.phone);
    // formDataToSend.append('designation', formData.designation);

    // if (file) {
    //   formDataToSend.append('attachment', file);
    // }
    console.log("formDataToSend--=-=-==>", formData)
    try {
      const response = await fetch('http://localhost:4000/mail-career', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const result = await response.json();
        setStatus(result.message);
        setFormData({ name: '', email: '', phone: '', designation:'' });
        setFile(null);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('Failed to send message. Please try again.');
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Grid item xs={12} md={6}>
        <Card className='p-5 w-96 m-auto'>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Send Your Resume
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Your Name (required)"
                    variant="outlined"
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Your Email (required)"
                    variant="outlined"
                    fullWidth
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Phone number"
                    variant="outlined"
                    fullWidth
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <input
                    accept=".pdf,.doc,.docx"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    name="resume"
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span" fullWidth>
                      Upload Your Resume
                    </Button>
                  </label>
                </Grid> */}
                <Grid item xs={12}>
                  <div>
                    <label htmlFor="attachment" className="block mb-1">Attachment:</label>
                    <input
                      type="file"
                      id="attachment"
                      name="attachment"
                      onChange={handleFileChange}
                      className="w-full px-3 py-2 border rounded"
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    label="Select Designation"
                    variant="outlined"
                    fullWidth
                    name="designation"
                    value={formData.designation}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {designations.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Send
                  </Button>
                  {status && <p className="mt-4 text-center">{status}</p>}
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ResumeForm;
