import React from 'react'
import Img from "../../assets/precastBg.jpg"
import { Grid, Box } from '@mui/material'
import MainHead from '../common/MainHead'
import ParaText from '../common/ParaText'
import SubHead from '../common/SubHead'
import Carousel from '../images-slides/Carosel'
import ApartmentIcon from '@mui/icons-material/Apartment';
import checkImg from "../../assets/check.jpg"
import steelImg from "../../assets/steel.jpg"
import precastImg from "../../assets/precast.jpg"
import LazyLoadImage from '../common/LazyImage'

const Precast = {
  Design: [
    "Structural analysis using FEM software tools",
    "Linear static & Dynamic analysis",
    "Earthquake resistant design of building structures",
    "Reinforced concrete and Assembly connection design",
    "Detailed design of Precast elements & Stability calculations",
    "Design calculation reports for submission"],
  Drafting: [
    "General Arrangement Drawings",
    "Erection Drawings",
    "Element/Shop Drawings",
    "Reinforcement Schedules",
    "Bill Of Materials",
    "Precast 3D Modeling using Revit (Geometry, Connection & Reinforcement)"
  ],
  Exp1: [
    "Parking Structures",
    "Commercial and Offices",
    "Stadium and Arena",
    "Residential",
    "Healthcare"
  ],
  Exp2: [
    "Correctional Facilities/Cell Module",
    "Warehouse/Distribution Facilities",
    "Retaining Walls/Sound-walls",
    "Bridges",
    "GFRC"
  ],
  TiltUp: [
    "Panel Shop Drawings",
    "Erection Drawings",
    "Bill Of Materials",
    "Lifting and Bracing Design (Client proprietary software)",
    "Tilt-up 3D Modeling using AutoCAD/REVIT"
  ],
  SteelEngg1: [
    "Structural Analysis",
    "3D-Space frame analysis for building structures",
    "Design of structures based on ERFO or ASO methods"
  ],
  SteelEngg2:[
    "Gravity load analysis for Dead load, Live Load, Imposed load & Snow load combinations",
    "Lateral load analysis for Dead load, Wind load, Seismic load combinations"
  ],
  Erection: [
    "Different sizes of members",
    "Piece / shop marks / erection notes",
    "Loads on framing plan",
    "Connection details",
    "All dimensions (member spacing, elevation etc.)",
    "Different plate details",
    "Design calculation for connections",
    "Structural standard notes"
  ],
  Shop: [
    "Individual member details",
    "BOM (sizes, cut length, connected accessories, bolt sizes, quantities, weights)"
  ],
  BlotPlan: [
    "Details of base plate of column and embed plates"
  ],
  Micella: [
    "Framing plan",
    "Location of member",
    "Cross section elevation",
    "Member properties",
    "Piece marks",
    "Standard notes",
    "Connection details",
    "Detailed Anchor Blot Plan",
    "Details of base plate of column and embed plates"
  ],
  Software: [
    "SDS/2",
    "Tekla X-Steel",
    "AutoCAD"
  ],
  PreEngg1: [
    "Aircraft lougars",
    "Warehouses",
    "Multi Storage Buildings",
    "Cold Storage Strictures"
  ],
  PreEngg2: [
    "Manufacturing Facilities...",
    "Indoor Stadiums",
    "Commercial and Retall Centre's"
  ],
  PreEngg3: [
    "Gas stations",
    "Churches",
    "Suits"
  ],
  PreEngg4: [
    "Office Buildings",
    "Agriculture Building",
    "Railway Platform Shelters"
  ]
}



const Precasting = () => {
  return (
    <>
      {/* <div className="relative h-screen overflow-hidden">
        <div
          className={`absolute inset-0 bg-slate-900`}
        >
          <img src={Img} alt={"img"} className="w-full h-full object-cover opacity-30" />
        </div>
        <div className='absolute h-full inset-0 flex flex-col justify-center items-center px-5'>
          <div className={`text-5xl max-w-3xl text-center text-white font-black mb-4 tracking-[.20em] leading-normal`}>
            Smart Construction Solutions <br />at Your Service
          </div>
        </div>
      </div> */}
    <Box className='bg-yellow py-10 text-white'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} container justifyContent={"center"} alignContent={"center"}>
          <div className="py-10 w-[600px]">
            <SubHead align={"left"}>PRECAST DESIGN AND <span className='text-secondary'>DRAFTING SERVICES</span></SubHead>
            <ParaText align={"left"}>Team works very close with Precast and Concrete Manufacturers
              around the globe with the aim to enhance their overall project efficiency and
              profit margins. From Design to Drafting we offer a whole bunch of valuable
              services under one roof. We have a persistent track record of supporting our
              clients with timely delivery and quality work.
            </ParaText>
          </div>
        </Grid>
        <Grid item xs={12} md={6} alignItems={"center"}>
          <div className="max-w-xl">
            <LazyLoadImage src={precastImg} />
          </div>
        </Grid>
      </Grid>
      </Box>
      <Box className='bg-yellow py-10 text-white'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubHead align={"center"}>OUR <span className='text-secondary'>OFFERINGS</span></SubHead>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="center">
            <div className="pt-5 w-[600px]">
              <SubHead align={"left pb-2"}>PRECAST DESIGN SERVICES</SubHead>

              {Precast.Design.map(d => <ParaText align={"left"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="center">
            <div className="pt-5 w-[600px]">
              <SubHead align={"left pb-2"}>PRECAST DRAFTING SERVICES</SubHead>

              {Precast.Drafting.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>

          <Grid item xs={12} md={6} container justifyContent="center">
            <div className="pt-4 w-[600px]">
              <SubHead align={"left"}>PRECAST PROJECT EXPERIENCE</SubHead>
              {Precast.Exp1.map(d => <ParaText align={"left"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="center">
            <div className="pt-4 w-[600px]">
              {Precast.Exp2.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} className='py-10'>
        <Grid item xs={12} md={6} container justifyContent="center">
          <div className="pt-5 w-[600px]">
            <SubHead align={"left pb-2"}> <span className='text-secondary'>TILT-UP</span> SERVICES</SubHead>
            <ParaText align={"left"}>Team has hands-on 15 years of combined experience in tilt-up and
              bracing designs using AutoCAD and REVIT as tools. Our detailed drawings
              includes detailed sheet for each panel along with Bills of Material,
              manufacturers hardware instructions and specifications. Utilizing current
              technology and state-of-the-art techniques and software, our analysis
              capabilities and design experience gives us a high level of engineering
              service. Our team works closely with clients to provide expected service and
              on-time delivery.
            </ParaText>
          </div>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="center">
          <div className="pt-5 w-[600px]">
            <SubHead align={"left pb-2"}>OUR <span className='text-secondary'>OFFERINGS</span></SubHead>
            {Precast.Drafting.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
          </div>
        </Grid>
      </Grid>
      <Box className='bg-yellow py-10 text-white'>
        <Grid container spacing={2}>
        <Grid item xs={12} md={6} container alignItems={"center"} justifyContent={"center"}>
          <div className="max-w-xl">
            <LazyLoadImage src={steelImg} />
          </div>
        </Grid>
          <Grid item xs={12} md={6} container justifyContent="center" alignContent={"center"}>
            <div className="py-10 w-[600px]">
              <SubHead align={"left"}><span className='text-secondary'>STRUCTURAL STEEL </span>ENGINEERING SERVICES</SubHead>
              <ParaText align={"left"}>Team works close with Steel Fabricators and Contractors to provide steel
                detailing services viz. for structures like residential, commercial, industrial
                and others. Our structural steel services include Design, 3D Modeling,
                Fabrication drawings, Bill of Materials and CNC files as a package.
              </ParaText>
            </div>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignContent={"center"}>
          <div className="pt-10 w-[600px]">
            <SubHead align={"center"}>OUR <span className='text-secondary'>OFFERINGS</span></SubHead>
          </div>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="center">
            <div className="pt-5 w-[600px]">
              {Precast.SteelEngg1.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="center">
            <div className="pt-5 w-[600px]">
              {Precast.SteelEngg2.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} className='py-10'>
        <Grid item xs={12} md={6} container justifyContent="center">
          <div className="pt-5 w-[600px]">
            <SubHead align={"left pb-2"}> <span className='text-secondary'>ERECTION </span>FRAMING PLAN</SubHead>
            {Precast.Drafting.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
          </div>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="center">
          <div className="pt-5 w-[600px]">
            <SubHead align={"left pb-2"}><span className='text-secondary'>SHOP</span> DRAWINGS</SubHead>
            {Precast.Shop.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
          </div>
          <div className="pt-5 w-[600px]">
            <SubHead align={"left pb-2"}><span className='text-secondary'>DETAILED ANCHOR</span> BLOT PLAN</SubHead>
            {Precast.BlotPlan.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
          </div>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="center">
          <div className="pt-5 w-[600px]">
            <SubHead align={"left pb-2"}>Detailing of Miscellaneous Members Viz. <span className='text-secondary'>Staircases, Handrails, Trusses, etc. </span></SubHead>
            {Precast.Micella.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
          </div>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="center">
          <div className="pt-5 w-[600px]">
            <SubHead align={"left pb-2"}><span className='text-secondary'>SOFTWARE</span></SubHead>
            {Precast.Software.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
          </div>
        </Grid>
      </Grid>
      <Box className='bg-yellow py-10 text-white'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container justifyContent="center">
            <div className="pt-5 w-[600px]">
              <SubHead align={"left pb-2"}><span className='text-secondary font-black'>Pre-ENGINEERED METAL BUILDINGS </span>DETAILINGS SERVICES</SubHead>

              <ParaText align={"left"}>
                Reinfo is a leading Engineering firm in Pre-engineered Metal Building
                (PEMB) detailing services. Over the years, we have gained a strong foothold
                in the global market through our stellar services by following customer
                centric approach. We detail Pre-Engineered Metal Buildings and Hybrid
                Structures ranging from simple to complex. Our structural and PEMR/PER
                detailing teams together make a winning combination in hanging any kind
                hybrid structures. we can design and detall drawings for PEMB/PEB or any
                other combination of services - a feature that Team is proud of.
              </ParaText>
            </div>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="center">
            <div className="pt-5 w-[600px]">
              <SubHead align={"left pb-2"}>PRECAST DRAFTING SERVICES</SubHead>

              {Precast.Drafting.map(d => <ParaText align={"left "}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <SubHead align={"center py-4"}>OUR <span className='text-secondary'>OFFERINGS</span></SubHead>
          </Grid>
          {/* <Grid item xs={12} md={3} container justifyContent="right">
            <div className="pt-5 w-[300px]">
              {Precast.PreEngg1.map(d => <ParaText align={"left px-8"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
          <Grid item xs={12} md={3} container justifyContent="center">
            <div className="pt-5 w-[300px]">
              {Precast.PreEngg2.map(d => <ParaText align={"left px-0"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
          <Grid item xs={12} md={3} container justifyContent="right">
            <div className="pt-5 w-[300px]">
              {Precast.PreEngg3.map(d => <ParaText align={"left px-0"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid>
          <Grid item xs={12} md={3} container justifyContent="left">
            <div className="pt-5 w-[300px]">
              {Precast.PreEngg4.map(d => <ParaText align={"left px-0"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
          </Grid> */}
          <Grid item xs={12} container justifyContent="center">
          <div className="flex flex-wrap justify-left space-x-0 lg:space-x-12 lg:flex-nowrap">
            <div className="max-w-xl">
              {Precast.PreEngg1.map(d => <ParaText align={"left px-8"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>

            <div className="max-w-xl">
              {Precast.PreEngg2.map(d => <ParaText align={"left px-0"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>

            <div className="max-w-xl">
              {Precast.PreEngg3.map(d => <ParaText align={"left px-0"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>

            <div className="max-w-xl">
              {Precast.PreEngg4.map(d => <ParaText align={"left px-0"}><span className='text-secondary font-black'><ApartmentIcon /></span> {d}</ParaText>)}
            </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Precasting
