import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import About from "./components/content/About";
import Navbar from "./components/Navbar/Navbar";
import Main from "./components/images-slides/Main";
import ElevateAppBar from "./components/Navbar/Nav1";
import Services from "./components/content/Services";
import Footer from "./components/footer/Footer";
import Home from "./components/content/Home";
import Life from "./components/content/Life";
import Careers from "./components/content/career/Careers";
import Contact from "./components/content/Contact";
import Works from "./components/content/Works";
import Precasting from "./components/content/Precasting";

export default function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<Services />} />
          <Route path="/precast" element={<Precasting />} />
          {/* <Route path="/works" element={<Works />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/life" element={<Life />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <ElevateAppBar className="absolute" />
        <Footer className="pt-10" />
      </Router>
    </>
  );
}