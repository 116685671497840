// import React from 'react'

// const SubHead = (props) => {
//   return (
//     <div className={`text-2xl text-${props.align} font-bold px-10 mb-4`} >
//       {props.children}
//     </div>
//   )
// }

// export default SubHead

import { useEffect, useRef, useState } from 'react';

const SubHead = (props) => {
  const [inView, setInView] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.unobserve(entry.target);
        }
      },
      {
        threshold: 0.1, // Adjust this value to trigger the animation earlier or later
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <>
      <style jsx>
        {`
          @keyframes slideInFromRight {
            from {
              opacity: 0;
              transform: translateY(20%);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .subHead {
            opacity: 0;
          }

          .subHead.in-view {
            animation: slideInFromRight 1.5s forwards;
          }
        `}
      </style>
      <div
        ref={elementRef}
        className={`text-2xl text-${props.align} font-bold px-10 mb-4 subHead ${inView ? 'in-view' : ''}`}
      >
        {props.children}
      </div>
    </>
  );
};

export default SubHead;
