import { Grid, Box, Card, Typography } from '@mui/material'
import { React, useEffect, useState } from 'react'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import ParaText from '../common/ParaText';
import MainHead from '../common/MainHead';
import SubHead from '../common/SubHead';
import Main from '../images-slides/Main';
import VideoBg from "../../assets/home.mp4"
// import bgImage from "../../assets/4.jpg"
import Carousel from '../images-slides/Carosel';
import bgImage from "../../assets/4.jpg";
import barImage from "../../assets/bars.jpg";
import pjImage from "../../assets/planning.jpg"
import Testimonial from '../images-slides/Testimonial';
import DynamicText from '../common/DynamicText';
import teamBg from "../../assets/teamBg.jpg"
import joinBg from "../../assets/joinBg.jpg"
import steel from "../../assets/steel.jpg"

const slides = [
    { image: bgImage, caption: 'Slide 1' },
    { image: pjImage, caption: 'Slide 3' },
    { image: steel, caption: 'Silde 4'}
];

const val = ["AMBITIOUS", "UNIQUE", "DIFFERENT"]


const Home = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="relative w-full h-screen bg-slate-900">
                <video className='absolute top-0 left-0 w-full h-full object-cover opacity-60 ' autoPlay loop muted>
                    <source src={VideoBg} type="video/mp4" />
                </video>
                <div className='absolute h-full inset-0 flex flex-col justify-center items-center px-5'>
                    <div className='flex flex-wrap flex-col justify-center items-center text-center'>
                        <div className='text-secondary font-extrabold text-6xl  mx-2 my-2 '>Build with</div>
                        <div className='text-secondary font-extrabold text-6xl  mx-2 mb-2 animated-right'>confidence</div>
                        <div className='text-white font-light text-2xl mx-2 animated-right '>India’s fastest growing Engineering Service Providers,
                        </div>
                        <div className='text-white font-light text-2xl mx-2 animated-right'>
                            We specialize in rebar detailing.</div>

                    </div>
                </div>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} className='flex justify-center'>
                    <div className="py-10 max-w-xl">
                        <MainHead align={"left"}>CREATING BESPOKE
                            SPACES THAT ENRICH THE
                            HUMAN <span className='text-secondary'>EXPERIENCE</span></MainHead>
                        <Grid container>
                            <Grid item xs={12} md={3}></Grid>
                            <Grid item xs={12} md={9} >
                                <ParaText align={"right"}>Reinfo Engineering and Technologies is one of
                                    the fastest-growing Structural Engineering
                                    Service Providers in the Construction
                                    Industry, presently providing outsourced
                                    rebar detailing, bar listing and project
                                    management services worldwide.</ParaText>
                                <ParaText align={"right"}>Reinfo accepts the projectregardless of its size and complexity.</ParaText>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className='py-10 '>
                        <Carousel slides={slides} />
                    </div>
                </Grid>
            </Grid>
            <Box className='bg-yellow py-10 text-white'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MainHead align={"center"}>OUR <span className='text-secondary'>SERVICES</span></MainHead>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="flex justify-center">

                            <VolunteerActivismOutlinedIcon style={{ fontSize: "100px" }}></VolunteerActivismOutlinedIcon>
                        </div>
                        <SubHead align={"center"}>REBAR PLACING DRAWINGS</SubHead>

                        <ParaText align={"center"}>Our Rebar Placing drawings are designed by a team of highly experienced Civil Engineers. They deliver high-quality shop drawings which are designed as per the ACI & CRSI standards. We use Rebar CAD to create the placing drawings and supply it in PDF and DWG format.</ParaText>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="flex justify-center">

                            <GppGoodOutlinedIcon style={{ fontSize: "100px" }}></GppGoodOutlinedIcon>
                        </div>
                        <SubHead align={"center"}>BAR LISTINGS</SubHead>
                        <ParaText align={"center"}>Our bar listings are available in the following formats: ASA, Soule (SLE), Pinnacle (PIN), Romac (ROM), Canada Wide (WID), ARMA+(CSF), Shear 97, RMS(CDI) and TSV.</ParaText>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="flex justify-center">
                            <Groups2OutlinedIcon style={{ fontSize: "100px" }}></Groups2OutlinedIcon>
                        </div>
                        <SubHead align={"center"}>PROJECT MANAGEMENT & COMMUNICATION</SubHead>
                        <ParaText align={"center"}>Our project managers coordinate with Contractors, Structural Engineer, Architects and Placing team, helping in avoiding time lapses in communication. This helps in completing the project without any delay, saving time for the customers.</ParaText>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2} className='py-10'>
                <Grid item xs={12} md={5} >
                    <div className="py-10 pl-20 max-w-xl">
                        <MainHead align={"left"}>BE </MainHead>
                        <MainHead align={"left"}><span><DynamicText val={val} className='text-secondary transition-transform duration-1000 ease-in-out transform translate-y-full' /></span></MainHead>

                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    <div className="max-w-3xl">

                        <ParaText align={"left"}>Our Vision is to establish Reinfo as a reputed brand in the Civil Engineering Industry that is known for its Quality and Integrity and to expand our focus area over the years, gaining a high degree of satisfied customers and employees.</ParaText>
                        <ParaText align={"left"}>Our mission is to become the Preferred Rebar Detailer in the Industry, by not only producing quality Rebar Placing drawings but also making them easy for Fabrication and Placing and managing the project until its completion. This is our keystone for completing a project cost-effectively.</ParaText>

                        {/* <Grid container spacing={2} className='px-10'>
                            <Grid item xs={12} md={4}>contet</Grid>
                            <Grid item xs={12} md={4}>contet</Grid>
                            <Grid item xs={12} md={4}>contet</Grid>
                        </Grid> */}
                    </div>

                </Grid>

            </Grid>
            <div className="relative w-full mx-auto overflow-hidden bg-black">
                <div className="relative h-screen overflow-hidden">
                    <div
                        className={`absolute inset-0 bg-slate-900`}
                    >
                        <img src={teamBg} alt={"img"} className="w-full h-full object-cover opacity-40" />

                    </div>
                </div>
                <div className="absolute top-1/2 left-0 max-w-3xl px-10 transform -translate-y-1/2 text-white ">
                    <div className={`text-2xl text-left font-black  mb-2`}>
                        THE TEAM
                    </div>
                    <div className={`text-4xl text-left font-bold mb-4 leading-normal`}>
                        OUR TEAM IS AN
                        ASSEMBLY OF
                        IMAGINATIVE AND
                        ENERGETIC CIVIL
                        ENGINEERS.

                    </div>
                    <Button variant="contained" size='large' color='error' endIcon={<ArrowForwardIcon style={{ fontSize: "30px" }} />} onClick={() => navigate("/about")}><div className='text-2xl text-center font-bold mx-5 my-2'>MEET THE TEAM</div></Button>
                </div>


            </div>
            <div className='py-10'>
                <Testimonial />
            </div>
            <div className="relative w-full mx-auto overflow-hidden pb-10">
                <div className="relative h-screen overflow-hidden">
                    <div
                        className={`absolute inset-0 bg-slate-900`}
                    >
                        <img src={joinBg} alt={"img"} className="w-full h-full object-cover opacity-40" />

                    </div>
                </div>
                <div className="absolute h-full inset-0 flex flex-col justify-center items-center text-white px-5">
                    <div className={`text-5xl text-center max-w-3xl font-black  mb-6 leading-tight`}>
                        We are Optimists who
                        love to work together
                    </div>
                    <div className={`text-2xl max-w-2xl text-center font-bold mb-4 leading-tight`}>
                        Our team consists of Highly Experienced Civil Engineers who are
                        very enthusiastic and committed to delivering customer needs.

                    </div>
                    <Button variant="contained" size='large' color='success' onClick={() => navigate("/careers")} ><div className='text-2xl text-center font-bold mx-3 my-2'>JOIN OUR TEAM</div></Button>
                </div>
            </div>

        </>
    )
}

export default Home
