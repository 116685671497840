import React, { useState, useEffect, useRef } from 'react';

const LazyLoadImage = ({ src, className, alt }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Mark as visible when in the viewport
          observer.disconnect(); // Stop observing after it becomes visible
        }
      },
      { threshold: 0.5 } // Trigger when 10% of the image is visible
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) observer.disconnect();
    };
  }, []);

  return (
    <img
      ref={imageRef}
      src={src}
      alt={alt}
      className={`transition-all duration-700 ease-in-out ${
        isVisible
          ? 'shadow-[0px_10px_10px_0px_rgba(0,0,0,0.7)] scale-90'
          : 'opacity-0 scale-80'
      } ${className}`}
    />
  );
};

export default LazyLoadImage;
