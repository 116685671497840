import React, { useState } from 'react'
import AddchartIcon from '@mui/icons-material/Addchart';
import { Grid, Box, Card } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LazyLoadImage from '../common/LazyImage';


import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';

import ParaText from '../common/ParaText';
import MainHead from '../common/MainHead';
import SubHead from '../common/SubHead';
import Main from '../images-slides/Main';
import bgImage from "../../assets/4.jpg";
import barImage from "../../assets/bars.jpg";
import pjImage from "../../assets/planning.jpg"
import CollapseTab from '../common/CollapseTab';
import rebarImg from "../../assets/rebar.jpg"
import checkImg from "../../assets/check.jpg"
import serviceBg from "../../assets/serviceBg.jpg"
import Testimonial from '../images-slides/Testimonial';
import Precasting from './Precasting';

const IconHead = () => {
    return (
        <>

        </>
    )
}
const services = ["REBAR PLACING DRAWINGS", "BAR LISTING", "PRECASTING", "PROJECT MANAGEMENT & COMMUNICATION"]

const Services = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div className="relative h-screen overflow-hidden">
                <div
                className={`absolute inset-0 bg-slate-700`}
                >
                <img src={serviceBg} alt={"img"} className="w-full h-full object-cover opacity-50" />
                </div>
                <div className="absolute h-full text-center inset-0 flex flex-col justify-center items-center">
                <div className={`text-6xl max-w-2xl font-black px-10 leading-15 text-white`}>
                Start your Project
                with us
                </div>
                <div className={`text-xl max-w-3xl font-mormal px-10 m-4 leading-normal text-white`}>
                Through a unique combination of engineering, construction, and design disciplines, Reinfo delivers world-class rebar placing drawings and bar listings to clients across a broad range of industries.
                </div>
                </div>
            </div>
            <Box className='bg-yellow py-10 mt-10 text-white'>
                <Grid container spacing={2} justifyContent="space-evenly" direction="row"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <MainHead align={"center"}>OUR SERVICE</MainHead>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div className="flex justify-center">

                            <VolunteerActivismOutlinedIcon style={{ fontSize: "100px" }}></VolunteerActivismOutlinedIcon>
                        </div>
                        <SubHead align={"center"}>REBAR PLACING DRAWINGS</SubHead>
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <div className="flex justify-center">
                            <GppGoodOutlinedIcon style={{ fontSize: "100px" }} ></GppGoodOutlinedIcon>
                        </div>
                        <SubHead align={"center"}>BAR LISTING
                        </SubHead>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div className="flex justify-center">
                            <Groups2OutlinedIcon style={{ fontSize: "100px" }}></Groups2OutlinedIcon>
                        </div>
                        <SubHead align={"center"}>PROJECT MANAGEMENT & COMMUNICATION
                        </SubHead>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div className="flex justify-center">
                            <HouseOutlinedIcon  style={{ fontSize: "100px" }}></HouseOutlinedIcon >
                        </div>
                        <SubHead align={"center"}>PRECASTING
                        </SubHead>
                    </Grid>
                </Grid>
            </Box>
            <Box className="">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="py-10 max-w-xl">

                            <LazyLoadImage src={rebarImg} />
                        </div>

                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center" alignContent={"center"}>
                        <div className="py-10 max-w-2xl">
                            <SubHead align={"left"}>Rebar Placing Drawings</SubHead>
                            <ParaText align={"left"}>Our Rebar Placing drawings are designed by a team of highly experienced Civil Engineers. They deliver high-quality shop drawings which are designed as per the ACI & CRSI standards. We use Rebar CAD to create the placing drawings and supply it in PDF and DWG format.</ParaText>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box className="">
                <SubHead align={"center text-secondary w-3/4 m-auto"} >Our placing drawings help in Fabrication and allow placing to be done cost-effectively, leading to the completion of the constructions without any delay.</SubHead>
                <Grid container spacing={2} className='py-10'>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Easy to understand</SubHead>
                            <ParaText align={"left"}>Each sheet is designed separately with unique bar bending schedule in the same sheet, helping the placer to work without difficulty. Enough cross-sections and enlarged details are taken at congested locations.</ParaText>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Fabrication and placer friendly</SubHead>
                            <ParaText align={"left"}>All unconfined bars are grouped to reduce the bar marks in a placer friendly way, making it easy for fabrication.</ParaText>
                        </div>
                    </Grid>
                </Grid>

            </Box>
            <Box className=" max-w-screen- pb-10">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} container alignContent={"center"} justifyContent="center">
                        <div className="max-w-2xl">
                            <MainHead align={"center"}>Three-level checking process</MainHead>
                            <ParaText align={"left"}>All our placing drawings go through a three-level checking process before submitting them to the client.</ParaText>
                            <div className='py-10'>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <div className='text-xl text-left font-medium px-10 mb-4' >
                                            Level 1 - Self Checking
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ParaText align={"left"}>
                                            The drawings are reviewed by the Detailing Engineer who is handling the project. All the corrections are done before it goes to the next level i.e., Quality Control.
                                        </ParaText>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <div className='text-xl text-left font-medium px-10 mb-4' >Level 2 - Quality Control</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ParaText align={"left"}>
                                            The drawings are reviewed by an experienced team leader to make sure all the details from the contract drawings are followed and ensure that each standard specified by the client is met. Then the drawings are sent to the review of Project Manager.
                                        </ParaText>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <div className='text-xl text-left font-medium px-10 mb-4' >
                                            Level 3 - Project Manager Review
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ParaText align={"left"}>
                                            This is the last and final review, where the project manager thoroughly checks the drawing from the aspects of Fabrication and Placing.
                                        </ParaText>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center" alignContent={"center"}>
                        <div className="max-w-xl">
                            <LazyLoadImage src={checkImg} />
                        </div>
                    </Grid>
                </Grid>


            </Box>
            <Box className='bg-yellow py-10 text-white'>
                <Grid container spacing={2} direction="row"
                    alignItems="center"
                >
                    <Grid item xs={12} md={6} container justifyContent="center" alignContent={"center"}>
                        <div className="max-w-xl">
                            <LazyLoadImage src={barImage} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center" alignContent={"center"}>
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>BAR LISTING</SubHead>
                            <ParaText align={"left"}>Our bar listings are available in the following formats: ASA, Soule (SLE), Pinnacle (PIN), Romac (ROM), Canada Wide (WID), ARMA+(CSF), Shear 97, RMS(CDI) and TSV.</ParaText>
                        </div>
                    </Grid>
                </Grid>
                <Box>

                    <Grid container spacing={2} className='pt-10'>
                        <Grid item xs={12} >
                            <SubHead align={"center text-secondary w-3/4 m-auto py-10"} >The bar lists are submitted seven to ten days before the set delivery date or the date mentioned by the Fabricator whichever is earlier.</SubHead>
                        </Grid>
                        <Grid item xs={12} md={6} container justifyContent="center">
                            <div className="max-w-2xl">

                                <SubHead align={"left"}>
                                    Material list separation</SubHead>
                                <ParaText align={"left"}>The materials are ordered based on their loading and unloading sequence and are split per shipping sequence.</ParaText>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} container justifyContent="center" >
                            <div className="max-w-2xl">

                                <SubHead align={"left"}>Avoid Light Load charges</SubHead>
                                <ParaText align={"left"}>By making sure that each group of releases achieves full truck, we make sure that the client avoids light load charges.</ParaText>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} container justifyContent="center">
                            <div className="max-w-2xl">

                                <SubHead align={"left"}>Patch lists
                                </SubHead>
                                <ParaText align={"left"}>Our team understands that changes are common and patch lists are important to keep the construction work going. So customer RFIs, Addendums and change requests from the field are highly prioritized and necessary patch lists are issued instantly for Fabrication by coordinating with Fabricator and Placing team to ensure timely delivery.</ParaText>
                            </div>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
            <Box className="py-10">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} container justifyContent="center" alignContent="center">
                        <div className="max-w-xl">
                            <LazyLoadImage src={pjImage} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center" alignContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Project Management And Communication</SubHead>
                            <ParaText align={"left"}>Project management and communication are handled by project managers who have 10+ years of experience in the field of Rebar Detailing.</ParaText>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box className="">
                <Grid container spacing={2} className='py-10'>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}  >Project Coordination</SubHead>
                            <ParaText align={"left"}>Our project managers coordinate all projects in a highly constructive manner wherein, the projects are initially studied for scope and queries are clarified by reaching out to customers. Our project managers coordinate with Contractors, Structural Engineer, Architects and Placing team, helping in avoiding time lapses in communication. This helps in completing the project without any delay, saving time for the customers.</ParaText>
                        </div></Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Construction Schedule Vs Approval Drawing Submission</SubHead>
                            <ParaText align={"left"}>The pace of the job and the allocation of the teams are planned based on the construction schedule. The scheduling of the job is done in such a way that all the approved shop drawings are submitted 6-8 weeks prior to steel on job date.</ParaText>
                        </div></Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Responding to the queries</SubHead>
                            <ParaText align={"left"}>All the questions and queries raised by the contractor and placing team are responded instantly via Email or Phone, to avoid any confusion.</ParaText>
                        </div></Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Shipping Sequence</SubHead>
                            <ParaText align={"left"}>Based on the requirements of the client, the Shipping, Loading and Unloading sequence are planned and material list is prepared and sent to the Fabricator, giving them enough lead time for Fabrication and the supply of Rebar.</ParaText>
                        </div></Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Listing out the RFIs</SubHead>
                            <ParaText align={"left"}>All the RFIs are listed and segregated separately for Fabricators, Contractors, Placing Team, Structural Engineers & Architects, making it easy to reach out to the concerned authority to get the RFIs answered.</ParaText>
                        </div></Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Coordinating with Placing Team and Structural Engineer</SubHead>
                            <ParaText align={"left"}>Project manager coordinates with the placing team and takes in all their suggestions for detailing, helping in carrying out the placing with ease. Then he gets confirmation from Structural engineer, if needed, before working on the placing drawings at the detailing stage.</ParaText>
                        </div></Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Guiding the placing team</SubHead>
                            <ParaText align={"left"}>The project manager guides the placing team and solves their queries until the completion of the project. If required, the manager will visit the job site from time to time.</ParaText>
                        </div></Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="max-w-2xl">
                            <SubHead align={"left"}>Field use drawings</SubHead>
                            <ParaText align={"left"}>The project manager contacts the customer for engineering markups and gets the field use drawings ready before the commencement of the project.</ParaText>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Precasting className="my-10" />
            <Testimonial />
        </>
    )
}

export default Services
