import React from 'react'

const TextImg = () => {
    return (
        <>
            <style jsx>{`
                @keyframes fadeIn {
                    0% {
                    opacity: 0;
                    transform: translateY(20px);
                    }
                    100% {
                    opacity: 1;
                    transform: translateY(0);
                    }
                }

                    @keyframes fadeInRight {
                    0% {
                    opacity: 0;
                    transform: translateX(100%);
                    }
                    100% {
                    opacity: 1;
                    transform: translateX(0);
                    }
                }

                .animated-text {
                    animation: fadeIn 1s ease forwards;
                }

                .animated-right {
                    animation: fadeInRight 1s ease forwards;
                }

                .word-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                .word {
                    font-size: 7rem;
                    font-weight: bold;
                    color: white;
                    animation: fadeIn 1s ease forwards;
                }

            `}</style>
            <div className='absolute h-full inset-0 flex flex-col justify-center items-center px-5'>
                <div className='text-secondary font-medium text-4xl mb-5 animated-text'>At REINFO</div>
                <div className='flex flex-wrap justify-center items-center text-center'>
                    <span className='text-white font-bold text-6xl mx-2 mt-2 animated-right'>We</span>
                    <span className='text-white font-bold text-6xl mx-2 mt-2 animated-right'>Dream</span>
                    <span className='text-white font-bold text-6xl mx-2 mt-2 animated-right'>To</span>
                </div>
                <div className='word-container'>
                    <span className='word'>Bu</span>
                    <span className='word'>il</span>
                    <span className='word'>d</span>
                </div>
            </div>
        </>
    )
}

export default TextImg
