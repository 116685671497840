import React from 'react'
// import { AppBar, Grid } from '@mui/material'
import TextImg from './TextImg'
import About from '../content/About'

const Main = (props) => {
  return (
    <>
      <div className="relative w-full h-screen bg-slate-900 overflow-hidden">
        {props.children}
        <TextImg></TextImg>
      </div>
    </>
  )
}

export default Main