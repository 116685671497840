import React from 'react'
import MainHead from '../common/MainHead'
import SubHead from '../common/SubHead'
import FormBg from "../../assets/careerFormBg.jpg"
import ParaText from '../common/ParaText'
import { Grid, Box, } from '@mui/material'
import { useNavigate } from 'react-router';
import contactBg from "../../assets/contactBg.jpg";

const Contact = () => {
  const navigate = useNavigate()
  return (
    <div>
      <div className="relative w-full h-screen bg-black">
        <img className='absolute top-0 left-0 w-full h-full object-cover opacity-30' src={contactBg} />
        <div className='absolute h-full inset-0 flex flex-col flex-wrap justify-center items-center px-5'>
        <Grid item xs={12}>
            <MainHead align={"center text-white text-5xl"}>
              How can we help you?{" "}
            </MainHead>
            <SubHead align={"center text-white text-4xl py-5"}>
              We’d love to chat and answer any questions you might have.
            </SubHead>
          </Grid>
        </div>
      </div>
      <div className="relative my-10 flex justify-center">
        <Box className='my-10 py-10 text-white'>
          <div className="flex flex-wrap justify-center space-x-0 lg:space-x-24 lg:flex-nowrap">

            <div className=" max-w-xl">

              <MainHead align={"right text-yellow  py-2"}>HEAD OFFICE</MainHead>
              <ParaText align={"right text-white text-4xl mb-1 max-w-96"}>
                Reinfo Engineering and Technologies, 2/15, B.L.N.Prasath Nagar,
                Valasaravakkam, Chennai - 600087, Tamilnadu, India.
              </ParaText>
              <hr className="max-w-40 ml-10 my-4" />
              <ParaText align={"right text-white text-4xl mb-0"}>
                samra@reinfoet.com <br />
                reinfoet@gmail.com
              </ParaText>
            </div>

            <div className=" max-w-xl">
              <MainHead align={"left text-yellow py-3"}>COORDINATORS</MainHead>
              <ParaText align={"left text-white text-4xl mb-0"}>
                India: +91 82206 68481
                <br />
                USA: +1 334 730 4962
                <br />
                Canada: +1 5195892393
                <br />
                Malaysia: +65 9880 1565
              </ParaText>
            </div>
          </div>
        </Box>
        <div
          className={`absolute inset-0 bg-black' -z-10 bg-black`}
        >
          <img src={FormBg} alt={"img"} className="absolute w-full h-full object-cover opacity-30" />
        </div>
      </div>
    </div>
  )
}

export default Contact
