import {useEffect, useState} from 'react'

const DynamicText = ({ val,className }) => {
    const [animate, setAnimate] = useState('fade-in');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
          setAnimate('fade-out');
    
          setTimeout(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % val.length);
            setAnimate('fade-in');
          }, 1000); // This timeout should match the duration of the fade-out animation
    
        }, 3000); // Change content every 3 seconds
    
        return () => clearInterval(interval);
    }, [val.length]);

    return (
        <>
            <style jsx>
                {`
                    .fade-in {
                        opacity: 0;
                        animation: fadeIn 1s ease-out forwards;
                        }

                        .fade-out {
                        opacity: 1;
                        animation: fadeOut 1s ease-out forwards;
                        }

                        @keyframes fadeIn {
                        0% {
                            opacity: 0;
                            transform: translateY(20px);
                        }
                        100% {
                            opacity: 1;
                            transform: translateY(0);
                        }
                        }

                        @keyframes fadeOut {
                        0% {
                            opacity: 1;
                            transform: translateY(0);
                        }
                        100% {
                            opacity: 0;
                            transform: translateY(-20px);
                        }
                    }`}
            </style>
            <div className={`${className} ${animate}`}>
                {val[currentIndex]}
            </div>
        </>
    )
}

export default DynamicText
