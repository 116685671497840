import { Grid, Box, Card } from '@mui/material'
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import ParaText from '../common/ParaText';
import MainHead from '../common/MainHead';
import SubHead from '../common/SubHead';
import Main from '../images-slides/Main';

import Carousel from '../images-slides/Carosel';
import bgImage from "../../assets/3.jpg";
import barImage from "../../assets/bars.jpg";
import pjImage from "../../assets/planning.jpg"
import SolImage from "../../assets/solution.jpg"

const slides = [
    { image: pjImage, caption: 'Slide 1' },
    { image: barImage, caption: 'Slide 2' },
    { image: bgImage, caption: 'Slide 3' },
];

const About = () => {
    return (
        <div >
            <style jsx>{`
                @keyframes scaleAnimation {
                    0% {
                        transform: scale(1);
                    }
                    100% {
                        transform: scale(1.2);
                    }
                }

                .scale-animation {
                    animation: scaleAnimation 5s;
                }
            `}</style>
            <Box>
                <Main>
                <img 
                    className='absolute w-full h-full object-cover opacity-60 scale-animation' 
                    src={SolImage} 
                    alt="Background"
                />
                </Main>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} container justifyContent="right">
                    <div className="py-20 max-w-2xl">
                        <MainHead align={"left"}>ABOUT <span className='text-secondary'>REINFO</span></MainHead>
                        <SubHead align={"left"}>WE ARE STRUCTURAL REBAR DETAILING & ESTIMATION SERVICES PROVIDER</SubHead>
                        <ParaText align={"left"}>Reinfo Engineering and Technologies is one of the fastest-growing Structural Engineering Service Providers in the Construction Industry, presently providing outsourced rebar detailing, bar listing and project management services worldwide.</ParaText>
                        <ParaText align={"left"}>We provide accurate, complete and timely placing drawings and bar lists, helping Placers to adhere to their schedules.</ParaText>
                        <ParaText align={"left"}>Our team of professionals has in-depth knowledge on Detailing, Placing and Fabrication requirements, creating detailed placing drawings that stand out from the competition. The drawings that we offer are so detailed that contractors often refer to our drawings for visualization of the structures.</ParaText>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}  alignItems="middle">
                    <div className='py-20 max-w-2xl'>
                        <Carousel slides={slides} />
                    </div>
                </Grid>
            </Grid>
            <Box className='bg-yellow py-10 text-white'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MainHead align={"center"}>We create rebar designs that are
                            reliable and efficient.</MainHead>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="pt-5 max-w-lg">
                            <ParaText align={"left"}><span className='text-secondary font-black'>Our Vision</span> is to establish Reinfo as a reputed brand in the Civil Engineering Industry that is known for its Quality and Integrity and to expand our focus area over the years, gaining high degree of satisfied customers and employees.</ParaText>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <div className="pt-5 max-w-lg">
                            <ParaText align={"left"}><span className='text-secondary font-black'>Our Mission</span> is to become the Preferred Rebar Detailer in the Industry, by not only producing quality Rebar Placing drawings but also making them easy for Fabrication and Placing and managing the project until its completion. This is our keystone for completing a project cost-effectively.</ParaText>
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Box className='py-20'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <MainHead align={"center"}>OUR <span className='text-secondary'>VALUES</span></MainHead>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="flex justify-center">

                            <VolunteerActivismOutlinedIcon style={{ fontSize: "100px" }}></VolunteerActivismOutlinedIcon>
                        </div>
                        <SubHead align={"center"}>TRUST</SubHead>

                        <ParaText align={"center"}>Relationships and businesses are built on trust and loyalty. Our clients trust Reinfo and know that they would receive what they were promised. Our high level of sincerity and ethics are one of the reasons why customers keep coming back to us and refer us to others.</ParaText>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="flex justify-center">

                            <GppGoodOutlinedIcon style={{ fontSize: "100px" }}></GppGoodOutlinedIcon>
                        </div>
                        <SubHead align={"center"}>INTEGRITY</SubHead>
                        <ParaText align={"center"}>At Reinfo, our word is our promise. We are ethical, honest, accountable and deliver what we have assured.</ParaText>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="flex justify-center">
                            <Groups2OutlinedIcon style={{ fontSize: "100px" }}></Groups2OutlinedIcon>
                        </div>
                        <SubHead align={"center"}>COMMITMENT TO CUSTOMERS
                        </SubHead>
                        <ParaText align={"center"}>We are friendly, hardworking and loyal. We perform all projects of our clients with high levels of commitment. All conversations are detailed and smooth and we keep the client informed of each process as we understand the value of long-lasting business relationships.</ParaText>
                    </Grid>
                </Grid>
            </Box>
            {/* 







        Meet Team pending







             */}
            <Box className='bg-yellow pb-10 text-white'>
                <Grid container spacing={6} justifyContent="center">
                    <Grid item xs={12}>
                        <MainHead align={"center"}>Why <span className='text-secondary'>Choose Us?</span>
                        </MainHead>
                        <SubHead align={"center"}>We’re passionate about our work, keep our promises and solve problems without any complications.</SubHead>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Card className="py-3 max-w-xl m-auto">
                                <Grid container>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <SchoolOutlinedIcon className='text-yellow' style={{ fontSize: "75px" }}></SchoolOutlinedIcon>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <SubHead align={"left"}>Knowledge and Experience</SubHead>
                                        <ParaText align={"left"}>Our team of skilled engineers are passionate, energetic and keep studying about the advances in the Industry to keep themselves updated to offer high-quality service to our clients.</ParaText>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Card className="py-3 max-w-xl m-auto">
                                <Grid container>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <SquareFootOutlinedIcon className='text-yellow' style={{ fontSize: "75px" }}></SquareFootOutlinedIcon>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <SubHead align={"left"}>Attention to details</SubHead>
                                        <ParaText align={"left"}>Attention to details
                                        Our team studies every project in detail and constantly strives to provide solutions to our clients by adopting a progressive approach to the issues that they aren’t even aware of.</ParaText>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Card className="py-3 max-w-xl m-auto">
                                <Grid container>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <SupervisedUserCircleOutlinedIcon className='text-yellow' style={{ fontSize: "75px" }}></SupervisedUserCircleOutlinedIcon>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <SubHead align={"left"}>Adapting to the client’s needs</SubHead>
                                        <ParaText align={"left"}>We pride ourselves for being able to adapt to the needs of our clients. We are flexible enough to respond and deliver based on requirements & expectations.</ParaText>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>  
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Card className="py-3 max-w-xl m-auto">
                                <Grid container>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <HighlightAltOutlinedIcon className='text-yellow'style={{ fontSize: "75px" }}></HighlightAltOutlinedIcon>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <SubHead align={"left"}>Transparency</SubHead>
                                        <ParaText align={"left"}>Our working methods are transparent and we keep our clients informed of all activities that we carry out. We clear their queries in detail if they have any.</ParaText>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Card className="py-3 max-w-xl m-auto">
                                <Grid container>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <HeadsetMicRoundedIcon className='text-yellow' style={{ fontSize: "75px" }}></HeadsetMicRoundedIcon>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <SubHead align={"left"}>Communication and Coordination</SubHead>
                                        <ParaText align={"left"}>The communication between client and company is highly streamlined and each project is coordinated from start to finish. Our work hours are flexible, allowing us to attend to all our client needs.</ParaText>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Card className="py-3 max-w-xl m-auto">
                                <Grid container>
                                    <Grid item xs={2} paddingLeft={2}>
                                        <TrendingUpIcon className='text-yellow' style={{ fontSize: "75px" }}></TrendingUpIcon>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <SubHead align={"left"}>Proven Track Record</SubHead>
                                        <ParaText align={"left"}>Our team delivers our services with high standards of excellence with less downtime, faster problem resolution, simplified working procedures and streamlined communications.</ParaText>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default About
