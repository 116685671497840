import React, { useEffect, useState } from 'react'
import Img from "../../../assets/career.jpg"
import FormBg from "../../../assets/careerFormBg.jpg"
import DynamicText from '../../common/DynamicText'
import { Grid } from '@mui/material'
import MainHead from '../../common/MainHead'
import ParaText from '../../common/ParaText'
import ResumeForm from './CareerForm'

const val = ["Rebar Detailers", "Rebar Estimators", "Rebar Precast Engineers", "Team Leaders", "Project Managers"]

const Careers = () => {
  return (
    <>
      <div className="relative h-screen overflow-hidden">
        <div
          className={`absolute inset-0 bg-black`}
        >
          <img src={Img} alt={"img"} className="w-full h-full object-cover opacity-50" />
        </div>
        <div className="absolute h-full inset-0 flex flex-col justify-center items-center px-5">
          <div className={`text-5xl text-left font-black px-10 mb-4 text-white *:leading-normal`}>
            We’re looking for Talented
          </div>
          <DynamicText val={val} className={`text-white text-5xl text-left font-black px-10 mb-4 leading-normal`} />
        </div>
      </div>

      <Grid container spacing={2} className='py-20'>
        <Grid item xs={12}>
          <MainHead align={"center"}>Want to join us? Send in your resume.</MainHead>
          <ParaText align={"center"}>We’re looking for dedicated, talented people to join us in making Reinfo even better.</ParaText>
        </Grid>
      </Grid>

      {/* <div className="relative h-screen overflow-hidden">
        
      <Grid container spacing={2} className='py-10'>
          <Grid item xs={12} md={6} >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}><MainHead align={"left"}>COME WORK
                WITH US.</MainHead>
                <ParaText align={"left"}>We are heading towards becoming one of the leading
                  companies in the field of Rebar Detailing. We want you to
                  be a part of our spectacular team of passionate and
                  hardworking professionals. Drop-in your resume and our
                  team will contact you.</ParaText>
                <hr className='max-w-20 ml-10 pb-4' />
                <ParaText align={"left"}>MAIL US AT:
                </ParaText>
                <ParaText align={"left"}>reinfoet@gmail.com</ParaText>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <ResumeForm />
          </Grid>
        </Grid>
        <div
          className={`absolute inset-0 bg-black' -z-10`}
        >
          <img src={FormBg} alt={"img"} className="w-full h-[100%] object-cover opacity-70" />
          
        </div>
      </div> */}
      <div className="relative overflow-hidden flex justify-center">
        <Grid container spacing={2} className="py-20">
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <div className='m-auto w-4/6'>
              <MainHead align="left text-white">COME WORK WITH US.</MainHead>
              <ParaText align="left text-white">
                We are heading towards becoming one of the leading companies in the
                field of Rebar Detailing. We want you to be a part of our
                spectacular team of passionate and hardworking professionals.
                Drop-in your resume and our team will contact you.
              </ParaText>
              <hr className="max-w-40 ml-10 pb-4" />
              <ParaText align="left text-white">MAIL US AT:</ParaText>
              <ParaText align="left text-white">reinfoet@gmail.com</ParaText>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <ResumeForm />
          </Grid> */}
        </Grid>
        <div
          className={`absolute inset-0 bg-black' -z-10 bg-black`}
        >
          <img src={FormBg} alt={"img"} className="absolute w-full h-full object-cover opacity-30" />
        </div>
      </div>

    </>
  )
}

export default Careers
