import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CollapseTab = ({ expanded, handleExpandClick, index, children }) => {
    const isExpanded = expanded === index;

  return (
    <>
    <ExpandMore
            onClick={() => handleExpandClick(index)}
            aria-expanded={isExpanded}
            aria-label="show more"
        >
            <ExpandMoreIcon />
        </ExpandMore>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            {children}
        </Collapse>
    </>
        
  )
}

export default CollapseTab
