import React, { useState, useEffect } from 'react';

const Carousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // 3000 milliseconds = 3 seconds
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <div className="relative w-full max-w-2xl mx-auto overflow-hidden">
      <div className="relative h-96 overflow-hidden">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-transform duration-1000 ease-in-out transform ${index === currentIndex ? 'translate-x-0' : 'translate-x-full'}`}
            style={{ left: `${(index - currentIndex) * 100}%` }}
          >
            <img src={slide.image} onClick={nextSlide} alt={slide.caption} className="m-auto p-10 object-cover" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
