import React from "react";
import WorkInProgress from "../common/WorkInProgress";
import lifeBg from "../../assets/life.jpg";
import MainHead from "../common/MainHead";
import SubHead from "../common/SubHead";
import ParaText from "../common/ParaText";
import { Grid, Box } from "@mui/material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import joinBg from "../../assets/joinBg.jpg";

const Life = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="relative w-full h-screen bg-slate-900">
        <img
          className="absolute top-0 left-0 w-full h-full object-cover opacity-60"
          src={lifeBg}
        />
        <div className="absolute h-full inset-0 flex flex-col justify-center items-center px-5">
          <div className="text-white font-mediun text-7xl tracking-[.35em] mx-2 my-2 animated-right">
            WE WORK WITH
          </div>
          <div className="text-secondary font-bold text-8xl  mx-2 my-2 align-left animated-right">
            {" "}
            PASSION
          </div>
        </div>
      </div>
      <Box className="py-10 max-w-xl m-auto">
        <MainHead align={"center"}>
          Life At <span className="text-secondary">Reinfo</span>
        </MainHead>

        <ParaText align={"center"}>
          We at Reinfo, aim to provide a comfortable work experience that makes
          our employees feel energized, challenges their minds and creates an
          emotional bonding and togetherness within the organization.
        </ParaText>
      </Box>
      <Box className="bg-yellow py-10 text-white">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MainHead align={"center"}>
              Perks and <span className="text-secondary">Benefits</span>
            </MainHead>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="flex justify-center">
              <SportsSoccerIcon style={{ fontSize: "75px" }}></SportsSoccerIcon>
            </div>
            <SubHead align={"center"}>Live it up</SubHead>

            <ParaText align={"center"}>
              Team outing once a year and movie and dinner outings from time to
              time is part of the perks that you would enjoy at Reinfo. Just
              live it up!
            </ParaText>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="flex justify-center">
              <CardGiftcardIcon style={{ fontSize: "75px" }}></CardGiftcardIcon>
            </div>
            <SubHead align={"center"}>We value our people</SubHead>
            <ParaText align={"center"}>
              Additional allowances when you work overtime on weekends and when
              your working hour exceeds 12 hours are provided to help you work
              with enthusiasm. Leave encashment is given once a year, including
              sick leaves.
            </ParaText>
          </Grid>
          <Grid item xs={12} md={4} className="max-w-lg">
            <div className="flex justify-center">
              <HandshakeIcon style={{ fontSize: "75px" }}></HandshakeIcon>
            </div>
            <SubHead align={"center"}>Relax! we’ve got you covered</SubHead>
            <ParaText align={"center"}>
              Our employees are allowed to take an interest-free loan of up to
              1.5 times of their salary.
            </ParaText>
          </Grid>
        </Grid>
      </Box>
      <div className="relative w-full mx-auto overflow-hidden pt-10">
        <div className="relative h-screen overflow-hidden">
          <div className={`absolute inset-0 bg-slate-900`}>
            <img
              src={joinBg}
              alt={"img"}
              className="w-full h-full object-cover opacity-20"
            />
          </div>
        </div>
        <div className="absolute h-full inset-0 flex flex-col justify-center items-center text-white px-5">
          <div
            className={`text-5xl text-center max-w-3xl font-black  mb-6 leading-tight`}
          >
            Join Us
          </div>
          <div
            className={`text-2xl max-w-2xl text-center font-bold mb-4 leading-tight`}
          >
            Build and head extraordinary projects that other companies fear to
            take over. Have a thrilling and exciting adventure.
          </div>
          <Button
            variant="contained"
            size="large"
            color="success"
            onClick={() => navigate("/careers")}
          >
            <div className="text-xl text-center font-bold mx-3 my-2">
              Apply Now
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Life;
