import React, { useState, useEffect } from 'react';
import testBg from "../../assets/testBg.jpg"

const testimonial = [
  { 
      cName: "Gokul", 
      feedback: "They have moved beyond just shop drawings and developed a holistic service model that encompasses total project value. No longer just client service but focus on complete project ownership. I am pleased that they support us from shop drawings, project management, customer relations, and complete assessment. They represent the future of Rebar Detailing.", 
      location: "Chennai" 
  },
  { 
      cName: "Dhingaran", 
      feedback: "This day and age there are many rebar detailing services out there. Based on some of our high profile projects, my experiences with Reinfo services was a pleasant one. Their ability to meet the deadline without sacrificing high standards and precision set them apart from others", 
      location: "Chennai" 
  },
  { 
      cName: "Logesh", 
      feedback: "Reinfo is a pleasure to work with on all projects. They are very punctual from the first submission to the last revision and are very accommodating to any requests that you might have. Their drawings are very well drawn and detailed, never had any issue with details. I believe this team a very committed to detail and are very precise with everything that they do.", 
      location: "Bangalore" 
  },
  { 
      cName: "Prasanth", 
      feedback: "I was very impressed with Reinfo’s ability to develop rebar shop drawings and maintain the very aggressive schedule that the job required. They are also very responsive to changes created by both design and constructability reasons. We would certainly recommend them to any other concrete contractor in the industry.", 
      location: "Bangalore" 
  }
  
]

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 10000); // 3000 milliseconds = 3 seconds
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonial.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonial.length) % testimonial.length);
  };

  return (
    <div className="relative w-full mx-auto overflow-hidden">
      <div className="relative h-screen overflow-hidden bg-slate-900">
      <img src={testBg} alt="Testimonial" className="w-full h-full object-cover opacity-50" />
        {testimonial.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 `}
            style={{ left: `${(index - currentIndex) * 110}%` }}
          >
              <div className={`absolute inset-0 px-20 flex flex-col items-center justify-center transition-transform duration-1000 ease-in-out transform ${index === currentIndex ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="text-secondary font-black py-5 text-2xl">{slide.cName}</div>
                <div className="text-white font-black py-5 text-xl">{slide.feedback}</div>
                <div className="text-secondary font-black py-5 text-xl">-{slide.location}</div>

            </div>
          </div>
        ))}
      </div>
      <button onClick={prevSlide} className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white bg-opacity-50 p-2">
        &lt;
      </button>
      <button onClick={nextSlide} className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white bg-opacity-50 p-2">
        &gt;
      </button>
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2 p-4">
        {testimonial.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-white' : 'bg-gray-500'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Testimonial;
