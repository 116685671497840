import { Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import logoWhite from "../../assets/ReinfoWhiteLogo.png"


const contact = [
    { c: "India", ph: "+91 82206 68481" },
    { c: "USA", ph: "+1 334-730-4962" },
    { c: "Canada", ph: "+1 5195892393" },
    { c: "Malaysia", ph: "+65 9880 1565" }]

const Footer = () => {
    const navigate = useNavigate()

    return (
        <footer
            className={`bg-gray-800 p-10 text-white pb-3`}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} className='flex justify-center pb-5 '>
                    <h2 className="text-3xl pb-2 pr-10">LET’S BUILD SOMETHING GREAT. TOGETHER.</h2>
                    <Button variant="contained" size='large' color='error' onClick={()=> navigate("/contact")}><div className='text-xl text-center font-medium mx-3 my-2'>START YOUR PROJECT</div></Button>

                </Grid>
            </Grid>
            <Grid container spacing={2} className='py-5'>
                <Grid item xs={12} md={3}
                    container
                    direction="column"
                    justifyContent="center">
                        <img src={logoWhite} className="pl-2 w-64 flex justify-center" onClick={()=> navigate("/")} />
                </Grid>
                <Grid item xs={12} md={9}>
                    <div className="container mx-auto flex flex-wrap justify-around">
                        <div className="w-full sm:w-2/6 text-center md:text-left mb-6 sm:mb-0">
                            <h4 className="text-xl font-black mb-3">USEFUL LINKS</h4>
                            <ul className='cursor-pointer'>
                                <li className="mb-2 text-lg" onClick={()=> navigate("/about")}>About Us</li>
                                <li className="mb-2 text-lg" onClick={()=> navigate("/service")}>Services</li>
                                <li className="mb-2 text-lg" onClick={()=> navigate("/works")}>Our Works</li>
                                <li className="mb-2 text-lg" onClick={()=> navigate("/life")}>Life At Reinfo</li>
                                <li className="mb-2 text-lg" onClick={()=> navigate("/career")}>Careers</li>
                                <li className="mb-2 text-lg" onClick={()=> navigate("/contact")}>Contact Us</li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-2/6 text-center sm:text-left mb-6 sm:mb-0">
                            <h4 className="text-xl font-black mb-3">SOCIAL MEDIA</h4>
                            <p className="mb-4 text-lg">LinkedIn</p>
                            <h4 className="text-lg font-black mb-3">PROJECT COORDINATOR</h4>
                            {
                                contact.map((c) => 
                                    <p className="mb-1 text-lg "><span className='text-bold'>{c.c}:</span> {c.ph}</p>
                                )
                            }
                        </div>
                        <div className="w-full sm:w-2/6 text-center sm:text-left mb-6 sm:mb-0">
                            <h4 className="text-xl font-black mb-3">ADDRESS</h4>
                            <p className="mb-4 text-lg">
                                2/15, B.L.N.Prasath Nagar, <br />
                                Valasaravakkam, Chennai - 600087,<br />
                                Tamilnadu, India.
                            </p>
                            <h4 className="text-lg font-black">EMAIL</h4>
                            <p className="text-lg">samra@reinfoet.com</p>
                            <p className="mb-2 text-lg">reinfoet@gmail.com</p>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <div className="border-t h-0.5 border-gray-700 text-center pb-2" />
                <p className='text-xs text-center'>© REINFO ENGINEERING AND TECHNOLOGIES. ALL RIGHTS RESERVED 2024</p>
            {/* </div> */}
        </footer>
    );
}

export default Footer;
