// ElevateAppBar.js
import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from "../../assets/logo.png"
import logoWhite from "../../assets/LogoWhite.png"

const MenuList = [
    {
        id: 2,
        name: "About",
        link: "/about"
    },
    {
        id: 3,
        name: "Service",
        link: "/service"
    },
    // {
    //     id: 4,
    //     name: "Precasting",
    //     link: "/precast"
    // },
    // {
    //     id: 5,
    //     name: "Works",
    //     link: "/works"
    // },
    {
        id: 6,
        name: "Life at Reinfo",
        link: "/life"
    },
    {
        id: 7,
        name: "Careers",
        link: "/careers"
    },
    {
        id: 8,
        name: "Contact",
        link: "/contact"
    },

];

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

function ElevateAppBar(props) {
    const [scrolled, setScrolled] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Reset scroll state when the route changes
        setScrolled(false);
        window.scrollTo(0, 0); // Optionally scroll to the top on route change
    }, [location.pathname]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClickLogo = () => {
        navigate("/")
    }

    return (
        <React.Fragment>
            {/* <CssBaseline /> */}
            <ElevationScroll {...props}>
                <AppBar className='transition-colors ease-out duration-500' style={{
                    background: scrolled
                        ? 'rgba(255,255,255,1)'
                        : 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0))',
                    boxShadow: scrolled ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(0, 0, 0, 0)',
                }}>
                    <Toolbar className="flex justify-between">
                        {/* <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}> */}
                            <img src={scrolled? logo : logoWhite} className="w-32" onClick={handleClickLogo} />
                        {/* </IconButton> */}
                        
                        <div className="hidden sm:flex flex-1 justify-end">
                            {MenuList.map((d) => (
                                <Button
                                    className="mx-2 font-bold"
                                    color="success"
                                    key={d.id}
                                    component={Link}
                                    to={d.link}
                                >
                                    <div className={`hover:text-secondary font-bold ${location.pathname === d.link ? 'text-secondary' : scrolled ? 'text-black' : 'text-white'}`}>
                                        {d.name}
                                    </div>
                                </Button>
                            ))}
                        </div>
                        <div className="sm:hidden flex justify-end">
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                                <MenuIcon color="error" />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {MenuList.map((d) => (
                                    <MenuItem
                                        key={d.id}
                                        onClick={handleMenuClose}
                                        component={Link}
                                        to={d.link}
                                    >
                                        {d.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </Toolbar>

                </AppBar>
            </ElevationScroll>
            <Toolbar />
        </React.Fragment>
    );
}

export default ElevateAppBar;
