// import React from 'react'

// const MainHead = (props) => {
//   return (
//    <> 
//       <style jsx>
//         {`
//           @keyframes easeIn {
//             from {
//               opacity: 0;
//               transform: translateY(20px);
//             }
//             to {
//               opacity: 1;
//               transform: translateY(0);
//             }
//           }

//           .line {
//             opacity: 0;
//           }

//           .line.in-view {
//             animation: easeIn 0.5s forwards;
//           }`
//         }
//       </style>
//       <div className={`text-4xl text-${props.align} font-bold px-10 mb-4 line`}>
//         {props.children}
//       </div>
//     </>
//   )
// }

// export default MainHead


import { useEffect, useRef, useState } from 'react'

const MainHead = (props) => {
  const [inView, setInView] = useState(false)
  const elementRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true)
          observer.unobserve(entry.target)
        }
      },
      {
        threshold: 0.1, // Adjust this value to trigger the animation earlier or later
      }
    )

    if (elementRef.current) {
      observer.observe(elementRef.current)
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current)
      }
    }
  }, [])

  return (
    <> 
      <style jsx>
        {`
          @keyframes easeIn {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          .line {
            opacity: 0;
          }

          .line.in-view {
            animation: easeIn 1s forwards;
          }`
        }
      </style>
      <div
        ref={elementRef}
        className={`text-4xl text-${props.align} font-bold px-10 mb-4 line ${inView ? 'in-view' : ''}`}
      >
        {props.children}
      </div>
    </>
  )
}

export default MainHead
